@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap');

	/*========================================
	=                 GENERAL               =
	========================================*/

	.full-width {
		width: 100%;
	}

	.no-padding {
		padding: 0;
	}

.clearfix {
	clear: both;
}

.modal{
	padding-right: 0px !important;
}

html, body {
	height: 100%;
}

/*html {*/
/*	overflow: hidden;*/
/*}*/

*,
body,
h1, h2, h3, h4, h5, h6,
p, span {
	font-family: 'Open Sans Hebrew', sans-serif;
	font-weight: 400;
}

.new-ui * {
	font-family: 'Montserrat','Open Sans Hebrew','FontAwesome', sans-serif;
}

*:focus {
    outline: none;
}

#master-container {
	padding: 0px;
	height: 100vh;

}
#master-container {
  /*NEW DESIGN*/
  /*padding: 0px;*/
  /*height: 100vh;*/

}

@supports(padding: max(0px)) {
    #master-container {
        padding-top: env(safe-area-inset-top);
    }
}

.ltr {
	direction: ltr;
}

.loader {
	display: block;
	margin: 0 auto;
	padding: 15px;
	width:70px;
}

.charts .loader {
	text-align: center;
	margin: 0 auto;
}

.vertical-align {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.disabled_bg {
	background-color: #E0E0E036;
}

.disabled {
    color: #B8A6CC !important;
}

.col-centered {
    display:inline-block;
    float:none;
    text-align:left;
    margin-right:-4px;
}

#wrapper {
	padding-top: 70px;
	background-color: transparent;
	/*box-shadow: 0 0.125rem 0.75rem 0 rgba(30, 37, 45, 0.25);*/
}
@media screen and (max-width: 768px) {
  #wrapper {
    padding-top: 0;
  }
}
/* container settings: */

.changePassword,
.personal_info,
.about,
.help-page,
.account_settings,
.terms,
.stats,
.salesmen,
.payment,
.mailing,
.cms_dashboard,
.cms_events,
.cms_users,
.cms_reports,
.cms_edit_user,
.successful_order,
.cms_payment_form,
.cms_web_hooks {
	padding: 70px 20px 10px 20px;
	margin: 15px 0;
}
.cms_dashboard,
.cms_events,
.cms_users,
.cms_reports,
.cms_edit_user,
.cms_users-actions,
.cms_reports {
  background: #fff;
  color: black;
}

.alerts {
	padding: 10px 20px;
}

h5 {
	font-size: 1.1em;
}

/* webkit solution */
::-webkit-input-placeholder { text-align:right; }
/* mozilla solution */
input:-moz-placeholder { text-align:right; }


/* text input animation: */

.credit_form .wrapper {
    display: block;
    /*height: 200px;*/
    width: 100%;
    /*border: solid 1px #CCC;*/
    border-bottom: none;
    position: relative;
	overflow: hidden;
}
.credit_form .wrapper:after {
    content:"";
    background: #f30b88;
	background: -webkit-gradient(linear, left top, right top, from(#f30b88),to(#09d3db));
	background: linear-gradient(to right, #f30b88 0%,#09d3db 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f30b88', endColorstr='#09d3db',GradientType=1 );
    display: block;
    height:3px;
    width: 100%;
    position: absolute;
    bottom: 0;
	left: 110%;
	-webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.input {
	display: block;
	width: 100%;
	padding: 6px 12px;
	color: #555;
	line-height: 1.42857143;
	border: none;
	border-bottom: 3px solid #ccc;
}

.input:focus {
	-webkit-box-shadow: none;
	        box-shadow: none;
}

.credit_form .wrapper,
.credit_form .wrapper:hover:after,
.credit_form .wrapper:hover:before {
    left: 0;
}

	/*========================================
	=          EVENTS - HOME PAGE            =
	========================================*/

.events {
	background-color: #fff;
}

.events h2 {
	font-size: 1.3em;
	font-weight: 600;
}

.events article {
    padding-top: 1em;
    padding-bottom: 4em;
}

.events article header p.event_specs {
	/*font-family: 'Assistant', sans-serif;*/
	font-size: 1.3em;
	color: #252525;
	text-align: center;
	margin-top: 10px;
}

.events article header p.date {
	/*font-family: 'Assistant', sans-serif;*/
	font-size: 1em;
	color: #252525;
	text-align: center;
	line-height: 1;
	margin-top: 10px;
}

.imgPreview {
	background-color: #212931;
}

.imgPreview img {
	/*max-width: 400px;*/
	min-height: 400px;
	margin: 0 auto;
}


/*.social {
	margin-bottom: 13px;
}*/

.facebook {
	margin-bottom: 8px;
	font-size: 1em;
}

.fb-text, .fb-text-connect{
	padding-right: 0;
	padding-left: 0;
	padding-top: 5px;
	font-size: 1.4em;
}

.no_friends a {
  font-size: 1em;
  text-decoration: underline;
}

.facebook p {

	margin: 0;
}

.facebook .round {
	display: inline-block;
	display: inline;
}

.facebook .round-rtl {
	margin-right: -12px;
}

.facebook .round-ltr {
	margin-left: -12px;
}

.facebook .round img {
	width: 45px;
	height: 45px;
	border: 2px solid #fff;
	border-radius: 50%;
}

/* events block - User actions */

.events .actions {
	/*text-align: center;*/
	font-size: 0.8em;
	font-weight: 700;
}

.events .actions span.button {
	/*font-family: 'Assistant', sans-serif;*/
	font-size: 45px;
}

.events /*.actions .social*/{
	/*text-align: center;*/
	margin: 10px 0;
}

.shareOptions {
	/*position: absolute;*/
	background-color: #fff;
	padding: 0;
	/*left:20px;*/
	top: 0px;
	width: 100%;
}

.shareOptions div {
	padding: 0 5px;
}

.shareIcons {
	text-align: center;
	margin: 0 auto;
}

.shareIcons a img {
	width: 50px;
	margin: auto;
}

.shareTitle {
	text-align: center;
	font-size: 1.3em;
	margin-bottom: 0px;
	line-height: 1;
}

.shareOptions span img {
	padding: 0;
	margin: 0;
}

.blur {
	-webkit-filter: blur(2px);
   -moz-filter: blur(2px);
   -o-filter: blur(2px);
   -ms-filter: blur(2px);
   filter: blur(2px);
}



/* Toggle share buttons display */

div[class*=shareContainer] {
	display: none;

}

.closeShare {
	width: 12%;
}


	/*========================================
	=              CONTENT PAGES            =
	========================================*/

/* #page h1 {
	border-bottom: 2px solid #E0E0E0;
} */

#page h2 {
	text-align: right;
	margin-top: 10px;
}

	/*========================================
	=                USER PAGES              =
	========================================*/

.top-section{
	display:flex;
	align-items: center;
}

.registered_readers i {
	-webkit-transform: scale(-1, 1);
	transform: scale(-1, 1);
}

.center_image {
	margin: 0 auto;
}

.signin a {
	/*color: #252525;*/
	text-decoration: none;
	font-size: 1em;
	/*margin-right: 5px;*/
}

.signin a:hover {
	text-decoration: underline;
}

.facebook_login,
.google_login,
.general_login {
	text-decoration: none;
	text-align: center;
	font-size: 1.3em;
	color: #fff;
	background-color: #15B2C5;
	border-radius: 3px;
}

/* .general_login {
	background: url(/assets/images/CTA_loggin.png) no-repeat center;
} */

.facebook_login {
	background: url(/assets/images/FB_loggin.png) no-repeat center;
	color: #fff;
	background-size: contain;
}

.google_login {
	background: url(/assets/images/GOOGLE_loggin.png) no-repeat center;
	color: #252525;
}

.sm_login {
	/*background-size: contain;*/
    /*background-color: #cccccc;*/
    /*border-radius: 35px 35px 35px 35px;*/
    /*background-color: transparent;*/
    width: 100%;
    padding: 5px;
    margin: auto;
    /*margin-top: 10px;*/
    /*margin-right: 5%;*/
    /*margin-left: 5%;*/
    position: inherit;
    text-align: center;
}


.facebook_login:hover {
	text-decoration: none;
	color: #fff;
}

.google_login:hover {
	text-decoration: none;
	color: #252525;
}

.general_login:hover,
.back_to_login:hover,
.sm_login:hover {
	cursor: pointer;
}

.open_email_login {
	text-align: center;
	font-size: 1.2em;
	color: #999999;
	margin-top: 15px;
}

.open_email_login:hover {
	cursor: pointer;
	text-decoration: underline;

}

.signin_link,
.signin_link:hover {
	color: #EA3C93;
}

.login_link {
	font-weight: 600;
}

.forgot_password_link a,
.back_to_login {
	color: #999999;
}


	/*========================================
	=             EDIT EVENT PAGE            =
	========================================*/

.form-control[readonly]{
	background-color: transparent;
}


.status {
	margin-bottom: 70px; /* to avoid save-event button from hiding event steps */
}

.disclaimer {
	line-height: 1.2;
	font-size: 1em;
}

.status .save_event_btn {
	position: fixed;
	bottom: 0px;
	right: 0px;
	margin: 0px;
	padding: 10px 0;
	z-index: 1;
	background-color: #0096A7;
	border: none;
	color: #fff;
	font-size: 1.5em;
}
.status .save_event_btn:disabled {
	background-color: #bbbbbb;
}

.required {
	border: 1px solid red;
	padding: 0 5px;
}
.required_feild{
	border: 1px solid red;
}

.errors ul li {
	color: red;
}

.append-links {
	border: 1px solid #E0E0E0;
	display: inline-block;
}

.append-links .link-element {
	display: inline;
	padding: 0 15px;
	border-left: 1px solid #E0E0E0;
}

.append-links .link-element:last-child {
	border:none;
}

.append-links .link-element button {
	background-color: transparent;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.append-links .link-element button:hover {
	color: #6584BA;
}

input[name="url_friendly"]:-moz-placeholder {
    text-align: left;
}
input[name="url_friendly"]:-ms-input-placeholder {
    text-align: left;
}
input[name="url_friendly"]::-webkit-input-placeholder {
    text-align: left;
}

.required_text {
	font-size: 1em;
	color: #858585;
	font-weight: 100;
}

.discounts p,
.price p {
	font-size: 1em;
}

#address_box {
	border: 1px solid red;
	padding: 10px;
	margin-top: 10px;
}

#onCreateEvent textarea {
	border: 1px solid #ccc;
	-webkit-box-shadow: 0px 0px 10px #ccc;
	        box-shadow: 0px 0px 10px #ccc;
}

#onCreateEvent .item_title,
.cms_payment_form .item_title {
	padding: 5px 10px;
	background-color: #EB3A54;
	color: #fff;
	font-size: 1.3em;
	margin: -10px -10px 10px;
}

#onCreateEvent .item_title img {
	display: inline-block;
	margin-left: 10px;
}



#onCreateEvent .inner_nav button:not(.download) {
	background-color: #E5E5E5;
	border-radius: 5px;
	color: #fff;
	width: 100%;
}

#onCreateEvent .inner_nav button.active {
	background-color: #0096A7;
}

#onCreateEvent .inner_nav button.download {
	background-color: #EB3A54;
	border-radius: 5px;
	width: 100%;
}

#onCreateEvent .inner_nav button a,
#onCreateEvent .inner_nav button.download {
	text-decoration: none;
	color: #fff;
}



.inline_input {
	width: 50%;
	display: inline-block;
}

.vertical_align_p {
	display: inline-block;
	vertical-align: baseline;
}

#step_1 .fa-question-circle-o,
#step_3 .fa-question-circle-o,
#step_8 .fa-question-circle-o,
.ticket_info_btn .fa-question-circle-o,
.open_desc {
	font-size: 1.5em;
	vertical-align: middle;
	color: #13CAD6;
}

.open_desc {
	vertical-align: baseline;
}

#step_1 .fa-question-circle-o:hover,
#step_3 .fa-question-circle-o:hover,
#step_8 .fa-question-circle-o:hover,
.ticket_info_btn .fa-question-circle-o:hover,
.open_desc {
	cursor: pointer;
}

#seo_description {
	border-radius: 5px;
}

.fb_preview,
.thankyou_preview,
.google_preview {
	/*box-shadow: 0px 0px 5px 5px #e0e0e0;*/
	border: 1px solid #e0e0e0;
    border-radius: 5px;

}

/*.coupons-form,*/
.discount-list
/*.bank-account-form,*/
/*.gallery-controlls,*/ {
	display: none;
}

#cover_image img {
	display: unset;
}

.bank-account-form h4 {
	letter-spacing: 0;
}

.bank-account-form p {
	font-size: 1em;
}

.ticket-details,
.coupon-details {
	margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #D2D2D2;
    background-color: #FAFAFA;
}

.gallery,
.coupons,
.bank-account,
.marketing,
.event_description_btn,
.event_tickets_btn,
.save_event_btn {
	display: inline-block;
	position: relative;
}

.gallery button,
.coupons button,
.bank-account button,
.read-terms {
	margin: 10px 0;
}

/*.add-item,*/
.new_item_btn {
	display: none;
}

.remove-item {
	/*display: none;*/
	margin-right: 10px;
}

.capsWarning,
.specific-error,
.item-error,
.bank-error {
	color: red;
	display: block;
	line-height: 1.3;
}
.item-error.big-error {
  position: relative;
  top: 0;
  color: rgba(255,40,27,0.8);
  background: linear-gradient(0deg, rgba(255, 40, 27, 0.20) 0%, rgba(255, 40, 27, 0.20) 100%), #080416;
  padding: 8px 28px;
  font-family: 'Neue Haas Grotesk Display Pro', 'Open Sans Hebrew', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.8px;
  border-radius: 5px;
}
.item-error.big-error::before {
  content: '';
  position: absolute;
  left: 8px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  display: inline-block;
  background-image: url('/assets/images/svg-icons/close-circle_red.svg');
  background-size: 16px 16px;
}
.item-error.big-error span:nth-child(1) {
  font-size: 14px;
  font-family: 'Neue Haas Grotesk Display Pro', 'Open Sans Hebrew', sans-serif;
  font-weight: 600;
}
.item-success.big-success {
  position: relative;
  color: #25FF3B;
  background: rgba(37, 255, 59, 0.20);
  padding: 20px 40px;
  font-family: 'Neue Haas Grotesk Display Pro', 'Open Sans Hebrew', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.8px;
  border-radius: 10px;
  border: 1px solid;

}
.item-success.big-success span:nth-child(1) {
  font-size: 14px;
  font-family: 'Neue Haas Grotesk Display Pro', 'Open Sans Hebrew', sans-serif;
  font-weight: 600;
}
.item-success.big-success::before {
  content: '';
  position: absolute;
  left: 16px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  display: inline-block;
  background-image: url('/assets/images/svg-icons/tick-circle_ligth_green.svg');
  background-size: 16px 16px;
}

.item-success {
	color:green;
	display: block;
	line-height: 1.3;
}

.close-form {
	float:right;
}

.close-form img {
	width: 65%;
}

.close-address img {
	vertical-align: baseline;
	width: 45%;
}

.coupons-form span.open-settings:hover,
.coupons-form span.open-discounts:hover,
.close-form img:hover,
.remove-item:hover,
.add-item:hover,
div.coupons > span:hover,
div.bank-account > span:hover,
.add-image:hover,
.remove_image:hover {
	cursor: pointer;
}


.coupon_list ul {
	margin-right: 20px;
}

div.fileinputs {
	position: relative;
}

div.fileinputs div.fakefile {
	position: relative;
    top: -10px;
    left: 0px;
    z-index: 1;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    font-size: 1.1em;
    text-align: center;
}

div.fileinputs div.fakefile.active_btn {
    background-color: #EB3A54; /* red */
}

div.fileinputs div.fakefile.disabled_btn {
    background-color: #E5E5E5; /* gray */
}

div.fileinputs input.file {
	position: absolute;
	/*text-align: right;*/
	/* -moz-opacity: 0; */
	opacity: 0;
	z-index: 2;
	top: 0;
	right: 0;
	filter: alpha(0);
}

div.fileinputs img {
	display: inline-block;
}

div.fileinputs span {
	vertical-align: middle;
}

/* GALLERY SETTINGS */

.cover,
.bg,
.feed {
	border: 1px dashed #C0C0C0;
    padding: 20px;
    text-align: center;
}

.gallery-controlls h4 {
	font-weight: 600;
}

.gallery-controlls h4,
.gallery-controlls p {
	text-align: center;
	font-size: 1.2em;
	margin: 0;
}
.gallery-controlls button {
	/* background-color: #EB3A54; red */
    padding: 5px 10px;
    border-radius: 0;
	/* margin-bottom: 0.5em; */
}

.gallery-controlls .btn-group button:last-child {
padding:0px;
}

.gallery-controlls label{
	font-size: 1em !important;
	cursor: pointer;
}

.cover > p,
.bg > p,
.slider > p,
.feed > p {
	margin: 0;
}

.preview_box {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #E0E0E0;
}

.preview_text p {
	line-height: 1;
	font-size: 1em;
	text-align: right;
}

.remove_image img {
	display: inline-block;
}

.gallery-controlls p.info {
	color: blue;
	font-size: 1em;
	text-align: right;
}

.add-image {
	border: 1px solid #E0E0E0;
	color: #6f788a;
	width: 216px;
	padding: 75px 34px;
	margin: 20px 5px 0;
	text-align: center;
	font-size: 1.3em;
}

.gallery-controlls p a {
	color: #252525;
	font-size: 1em;
}

.new_cover_preview,
.bg .new_preview_image,
.new_feed_preview {
	display: none;
}

.gallery-uploaded-image {
	border: 1px solid #E0E0E0;
	width: 216px;
	height: 195px;
}

.gallery-uploaded-image img {
	width: 216px;
	height: 195px;
}

.gallery_youtube input {
	margin: 5px 0;
}

#colorPicker,
#colorPicker2 {
	/*background-image: url('/assets/images/colorPicker/select.png');*/
	/*position: absolute;*/
	/*top: 0;*/
	/*left: 0;*/
	width: 36px;
	height: 36px;
	background-repeat: no-repeat;
	background-size: cover;
}


.cover .changeColor,
.cover .confirmColor {
	display: none;
}

#colorPicker:hover,
#colorPicker2:hover {
	cursor: pointer;
}

#colorPicker div,
#colorPicker2 div {
	background-image: url('/assets/images/colorPicker/select4.png');
	background-position: center;
	position: absolute;
	top: 4px;
	left: 4px;
	width: 28px;
	height: 28px;

}

/* DISCOUNT LIST - COUPONS */

.download_discounts {
	color: #252525;
	font-size: 1em;
}

.download_format a {
	color: #252525;
	font-size: 1em;
}

.download_format a:hover {
	text-decoration: none;
}

.discount-list table th,
.discount-list table td {
	text-align: right;
}

.discount-list table tr:last-child {
	border-bottom: 1px solid #ddd;
}

.discount-list select {
	background-color: transparent;
	border: 1px solid #E0E0E0;
	border-radius: 3px;
	padding: 3px 5px;
}

.steps {
	text-decoration: underline;
}

/* terms and conditions: */

.preview > a{
	margin-top: 5px;
    margin-bottom: 5px;
    float: left;
    color: black;

}

.stat-text > p {
	font-size: 1em;
	text-align: center;
}

.choose_types{
	margin: 0 15px;
}

.preview{
	background-color: rgba(255, 255, 255, 0.875);
    -webkit-box-shadow: 0 0.125rem 0.75rem 0 rgba(30, 37, 45, 0.25);
            box-shadow: 0 0.125rem 0.75rem 0 rgba(30, 37, 45, 0.25);
    color: #212931;
}

.event-settings-title {
	font-size: 2em;
}

#onCreateEvent .restrictions {
	font-size:  1em;
	font-weight: 700;
}

.step_name {
	font-size: 1.3em;
    color: #3D4046;
}

.settings-container{
	padding-top: 15px;
	padding-bottom: 30px;
}

.urlf{
	direction: ltr;
}

#onCreateEvent{
	margin-bottom: 150px;
}

.terms {
	color: #212931;
}

.timer_description,
.ticket_description {
	font-size: 1em;
	color: #858585;
	/*margin-top: -10px;*/
	display: none;
}

input[name='counter_title'] {
	display: none;
}

button[name=check_friendly_url] {
	background-color: #EB3A54;
	border-color: #EB3A54;
	color: #fff;
}

#info_icons .modal-header {
	border: none;
}

.gotIt {
	background-color: #0096A7;
	border-radius: 5px;
	color: #fff;
	padding: 5px 0;
	text-align: center;
}

.gotIt button {
	background-color: transparent;
}

body.modal-open {
    overflow: visible;
}

	/*========================================
	=             EVENTS DASHBOARD           =
	========================================*/

.dashboard .event {
	border-bottom: 2px solid #E0E0E0;
	padding: 10px 0;
}

.dashboard .bright-divider {
	border-top: 1px solid #E0E0E0;
}

.event button {
	background-color: transparent;
}

.dashboard h1 {
	font-size: 1.7em;
	text-align: center;
}

/*.dashboard h2 {
	text-align: right;
	border-bottom: 5px double #f00d89;
	width: 50%;
}*/

.dashboard h3 {
	font-size: 1.3em;
	font-weight: 600;
	letter-spacing: 0;
}

.dashboard p {
	font-size: 1em;
	line-height: 1;
}

.dashboard .sum_tabs {
	padding: 0;
	margin-bottom: 10px;
}

.dashboard .sum_tabs > div {
	background-color: #0096A7;
	color: #fff;
	padding: 10px 0 15px 0;
}

.dashboard .sum_tabs > div span:first-child {
	font-size: 1em;
	display: block;
	margin: 0 auto;
	text-align: center;
}

.dashboard .sum_tabs > div span:last-child {
	font-size: 1em;
	display: block;
	margin: 0 auto;
	text-align: center;
    font-size: 2em;
    line-height: 1;
}

.dashboard .sum_tabs > div span .fa-shekel {
	font-size: 0.5em;
}

.dashboard input[name=search] {
	font-size: 20px;
}

.dashboard .agt_status img,
.salesmen .agt_status img {
	display: inline-block;
	width: 20px;
}

.dashboard .agt_status p,
.salesmen .agt_status p {
	margin-right: 5px;
	display: inline-block;
}

.dashboard .agt_status span,
.salesmen .agt_status span {
	font-weight: 600;
}

.dashboard .event_options .fa-angle-down,
.dashboard .agt_status_btn .fa-angle-down {
	font-size: 2em;
	color: #9B9C9F;
}

.dashboard .event_status span {
	font-weight: 600;
}

.dashboard .event_status .fa {
	font-size: 0.8em;
}

.dashboard .green {
	color: #3BA602;
}
.dashboard .blue {
	color: #0892A3;
}
.dashboard .orange {
	color: #FF921E;
}
.dashboard .gray {
	color: #D5D5D5;
}
.dashboard .red {
	color: #EB3A54;
}
.dashboard .black {
	color: #020202;
}

.affiliate_data {
	display: none;
}


.images {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
  	-webkit-box-align: center;
  	    -ms-flex-align: center;
  	        align-items: center;
}

.img_dash_placeholder { /* temporary! */
	position: relative;
    max-width: 100%;
    height: 150px;
}

#event_options {
	width: 50px;
}

#event_options:hover {
	cursor: pointer;
}

.dashboard {
	/*border: 1px solid #E0E0E0;*/
	padding: 10px 20px;
	margin: 15px 0;
}

.dashboard img {
	margin: 0 auto;
}

.sharer {
	margin-top:2em;
	cursor: pointer;
}

.sharer img {
	width:24px;
}

#open_event_options,
.open_reports,
.open_event_info,
#open_agent_status {
	top: 90px;
}

#open_agent_status .modal-header {
	border: none;
}

#open_agent_status .modal-header span {
	font-size: 1.3em;
}

#open_agent_status.modal.fade:not(.in) .modal-dialog {
	-webkit-transform: translate3d(0, 25%, 0);
	transform: translate3d(0, 25%, 0);
}

#open_event_options .modal-dialog,
#open_agent_status .modal-dialog,
#personal_info_modal,
.open_reports .modal-dialog,
.open_event_info .modal-dialog {
    width: 100%;
  	height: 100%;
  	margin: 0;
  	padding: 0;
}

#open_event_options .modal-content,
#open_agent_status .modal-content,
#personal_info_modal,
.open_reports .modal-content,
.open_event_info .modal-content {
	height: auto;
	min-height: 100%;
	border-radius: 0;
}

#open_agent_status .manage_event span {
	display: inline-block;
	line-height: 1.3;
}

#open_agent_status .manage_event p:first-child {
	font-size: 1.3em;
}


#open_agent_status .manage_event img {
	width: 30px;
	text-align: center;
	margin: 0 auto;
}

#open_event_options .action:hover{
	cursor: pointer;
}


#open_event_options .modal-content {
 -webkit-box-shadow: none;
 box-shadow: none;
 border:none;
 }


@media only screen and (max-width: 991px){

	#open_event_options,
	.open_reports,
	.open_event_info,
	#open_agent_status {
		top: 50px;
	}

}

.more_info_btn,.less_info_btn{
	cursor: pointer;
}

.dashboard .manage_event a {
	color: #252525;
	margin: 0 5px;
	font-size: 1.3em;
	line-height: 0;
}

.dashboard .manage_event>div {
	border-bottom: 1px solid #E0E0E0;
}

.dashboard .manage_event>div:last-child {
	border-bottom: none;
}

.manage_event a.delete-event {
	color: red;
}

.dashboard .event_details a,
.dashboard .event_details span {
	font-size: 1em;
	color: #252525;
}

.dashboard .event_details a {
	text-decoration: underline;
}

.dashboard .manage_event div span:hover {
	cursor: pointer;
}

.dashboard .manage_event button {
	background-color: transparent;
}

.pending_table td span i.fa-check {
	color: rgba(75, 192, 192, 1);
}

.pending_table td span i.fa-close {
	color: rgba(255,99,132,1);
}

.back_to {
	margin: 0px 0px 10px;
	text-align: left;
}

.back_to a {
	color: #252525;
	font-size: 1em;
}

.back_to a i.fa-chevron-left {
	color: #252525;
	font-size: 0.8em;
}


.dashboard .event_filter select {
	font-size: 20px;
	height: 45px;
}

.add_event {
	font-size: 1.3em;
    border-radius: 5px;
    padding: 3px 10px;
    color: #fff;
    background-color: #0096a7;
    width: 100%;
    display: block;
    text-align: center;
}

.add_event:hover {
	cursor: pointer;
}

.payment_details .terms_btn {
	background-color: transparent;
	text-decoration: underline;
}

.ticket_type .fa-exclamation-circle {
	color: red;
}


/* event_reports modal */

.participants_type,
.sellers_type,
.show_dates {
	display: none;
}

.events-info-links{
	text-align: center;
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 10px;
}

#event_info_body{
	overflow-y: auto;
    overflow-x: hidden;
    max-height: 53vh;
}

#event_info_body::-webkit-scrollbar{
	width: 10px;
	background-color: #F5F5F5;
}

#event_info_body::-webkit-scrollbar-thumb{

	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(153, 102, 255)),
									   color-stop(0.72, rgb(204, 102, 255)),
									   color-stop(0.86, rgb(255, 102, 255)));
}

.event_info_close{
	padding-top: 10px;
}

#events-info-footer{
	    padding: 5px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}


	/*========================================
	=                 READER                 =
	========================================*/

.participant_block {
	border-bottom: 1px solid #eee;
	padding: 5px;
	margin-top: 10px;
}

.rouded_image {
	width: 100px;
	height: 100px;
	border-radius: 50px; /* works for an image the size of 100px * 100px only! */
}

.already_entered .cancel {
	width: 100%;
	color: #fff;
	background-color: #ED3952;
}

	/*========================================
	=                SALESMEN                =
	========================================*/

.seller_options button {
	background-color: transparent;
}

.salesman_info {
	/* display: none; */
    position:fixed;
    top: 50%;
    left: 50%;
    width:100%;
    /*height:200px;*/
    margin-top: -50%; /*set to a negative number 1/2 of your height*/
    margin-left: -50%; /*set to a negative number 1/2 of your width*/
    border: 1px solid #ccc;
    background-color: #f3f3f3;
    padding: 15px 20px;
}

.salesman_info a {
	color: #337ab7;
	font-size: 0.8em;
}

.salesman_item {
	border-bottom: 1px solid #E0E0E0;
	padding: 15px 0;
}

/* .sellers_info .sub_sellers_list {
	display: none;
} */

.sellers_info .sub_sellers_list .sub_item {
	background-color: #f7f7f7;
}

.seller_types {
	display: inline;
}

.sub_sellers_btn:hover {
	cursor: pointer;
}

.dynamic_content span[id="phone"],
.dynamic_content span[id="email"],
.dynamic_content span[id="personal_link"] {
	display:none;
}

.salesmen .dynamic_content span i.cash {
	color: green;
}

.salesmen .dynamic_content span i.coupons {
	color: purple;
}

.salesman_info .close-form img {
	width: 45%;
}

.dynamic_content i:hover,
.dynamic_content span:hover {
	cursor: pointer;
}

.dynamic_content span i.fa-edit {
	color: rgba(255, 159, 64, 1);
}

.dynamic_content span i.fa-trash-o {
	color: rgba(255,99,132,1);
}

.dynamic_content i.fa-info-circle {
	color: rgba(54, 162, 235, 1);
}

.open_seller_coupons {
	top: 85px;
	max-height: 80%;
}

/* .open_seller_coupons .display_new_coupons {
	display: none;
} */



.open_seller_coupons table td {
	vertical-align: middle !important;
}

.open_seller_coupons .update:hover {
	cursor: pointer;
}


	/*========================================
	=               STATISTICS               =
	========================================*/

#cash_charge_modal,
#sales_drilldown_modal,
.open_reports,
#edit_user,
#change_password,
.open_edit_participant,
.open_event_commission,
#info_icons {
	top: 80px;
}

.stats th,
#cash_charge_modal th,
#sales_drilldown_modal th {
	text-align: right;
}

#cash_charge_modal td i {
	margin-right: 5px;
}

.stats .stats_sum td a,
.stats .tickets_sum td a,
.stats .discounts_sum td a,
.stats .salesmen_list td a {
	font-size: 1em;
	color: #252525;
}

.stats .salesmen_list td button {
	background-color: transparent;
}

.stats .salesmen_list .cash_charge_btn {
	cursor: pointer;
}

.stats .salesmen_list td i.fa-info-circle {
	color: rgba(54, 162, 235, 1);
	cursor: pointer;
}

.stats .salesmen_list td[id="men"],
.stats .salesmen_list td[id="women"],
.stats .salesmen_list td[id="total_register"],
.stats .salesmen_list td[id="tickets"],
.stats .salesmen_list td[id="credit"],
.stats .salesmen_list td[id="cash"],
.stats .salesmen_list td[id="free"],
.stats .salesmen_list td[id="total"],
.stats .salesmen_list .cash_to_charge,
.stats .salesmen_list td[id="before"],
.stats .salesmen_list td[id="amount"],
.stats .salesmen_list td[id="after"],
.stats .salesmen_list td[id="cash_sum"],
.stats .salesmen_list td[id="cred_sum"],
.hidden_data {

	display:none;
}

.table-responsive .table {
	max-width: none;
	-webkit-overflow-scrolling: touch !important;
}

.stat_tbl>tbody>tr>th{
	padding: 5px;
}


	/*========================================
	=              PARTICIPANTS              =
	========================================*/

.participants .event_text {
	font-size: 1.4em;
}

.participants .p-logo h1 {
	color: white;
	font-size: 1.8em;
	background-color: #3A94A5;
	margin:0;
}

.participants .sum_tabs {
	padding: 0;
	margin-bottom: 10px;
	cursor: pointer;
	display: inline-flex;
}

.participants .sum_tabs > div {
	border: solid 1px #3A94A5;
	line-height: 1.2;
	flex: 1;
}

.participants .sum_tabs > div span:first-child {
	font-size: 0.8em;
	display: block;
	margin: 0 auto;
	text-align: center;
    font-size: 2em;
    line-height: 1;
    padding-bottom: 5px;
    padding-top: 5px;
}

.participants .sum_tabs > div span:last-child {

    font-size: 1em;
	display: block;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 5px;
}

.participants .participant_list {
	margin: 0;
}

.participants .more_info {
	display: none;
	background-color: #f1f1f1;
}
.approved_box:hover,
.pending_box:hover,
.declined_box:hover,
.open_status:hover {
	background-color: #4E92A3;
	color:white;
}

.approved_box.active,
.pending_box.active,
.declined_box.active {
	background-color: #4E92A3;
	color:white;
}

.open_status span:first-child {
	color: #4E92A3;
}
.open_status:hover span {
	color:white;
}

/*.participants .more_info_btn,
.participants .sub_participant_btn {
	text-decoration: underline;
}*/

.participants .more_info_btn:hover,
.participants .sub_participant_btn:hover,
.participants .less_info_btn:hover {
	cursor: pointer;
}

.pending {
	font-size: 1.1em;
}

.participants_scroll{
  max-height: 40vh;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.participants_scroll::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.participants_scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(153, 102, 255)),
									   color-stop(0.72, rgb(204, 102, 255)),
									   color-stop(0.86, rgb(255, 102, 255)));
}


 .participants .participant_list {

	padding-bottom: 15px;
 }

 div[class^="more_details_"]  {
 	background-color: #f1f1f1;
	margin: 5% 0 0 0;
 }

#age_span {
     position: absolute;
    margin: 2px 4.5px;
    color: white;
    font-weight: bolder;
    font-size: 1.2em;
    cursor: default;
}

#age_span_fb {
     position: absolute;
    margin: -4px 5.5px;
    color: white;
    font-weight: bolder;
    font-size: 1.2em;
    cursor: default;
}


#toggle_details{
	line-height: 1.3;
    font-size: 1em;
    text-align: right;
}

#participant_more_info_p{
	line-height: 1;
	    font-size: 1em;
	    margin: 5px;
}

#participant_sub_info{
	padding: 10% 20% 0 0;
}

/*.sub_image {
	border-right: 2px solid #E0E0E0;
	padding: 0 15px;
}*/

.main_user_item {
    border-top: 1px solid #E0E0E0;
	padding: 5px 0;
}
.sub_user_item {
	padding: 10px 0;
}
/*.sub_users .sub_item{
    background-color: #f7f7f7;
}*/

/*.sub_users{
    margin-top: 15px;
}*/


.open_edit_participant {
	max-height: 80%;
}

.edit_participant {
	background-color: transparent;
}

#update_participant {
	padding: 0px;
}

	/*========================================
	=                MAILINGS                =
	========================================*/


.mailing .upload_mailing,
.mailing .single_mailing,
.mailing .free_text.sms {
	display: none;
}

.mailing textarea {
	border: 1px solid #ccc;
	-webkit-box-shadow: 0px 0px 10px #ccc;
	        box-shadow: 0px 0px 10px #ccc;
	border-radius: 5px;
}




	/*========================================
	=              PAYMENT PAGE              =
	========================================*/

/*.form_item,*/
.member_item {
	border-bottom: 1px solid #E0E0E0;
}

.member_item:last-child {
	border-bottom: none;
}

.event_details_payment {
	display: none;
	-webkit-box-shadow: 0px 0px 15px 1px #e0e0e0;
	        box-shadow: 0px 0px 15px 1px #e0e0e0;
	padding: 10px;
	border-radius: 5px;
}

.event_details_btn:hover {
	cursor: pointer;
}

.crossed {
	text-decoration: line-through;
}

.ticket_info,
.users_to_pay,
.payment_step,
.credit_form .cc_num,
.credit_form .exp_date,
.credit_form .cvv,
.close_payment,
.tranzilla_form,
.confirm_payment {
	display: none;
}

.users_to_pay table a {
	color: #252525;
	font-size: 1em;
}

.users_to_pay .pay_now:hover {
	cursor: pointer;
}

.credit_form_display {
	/*background-color: #2D2F35;*/
	/*color: #fff;*/
	display: none;
}

.credit_form h4 {
	color: #fff;
}

.credit_form .order_sum p {
	font-size: 1.1em;
}

#close_payment {
	padding: 0;
}

#tranzilla_form {
	height: 60vh;
}

#payment_counter {
	color: #fff;
}

.no_padding {
	padding-right: 0;
	padding-left: 0;
}

.manual_padding {
	padding-right: 15px;
	padding-left: 15px;
}

span#part_name {
	color: #3A94A5;
}



	/*========================================
	=              PERSONAL INFO             =
	========================================*/

.affiliates {
	display: none;
}

.affiliates table td a {
	color: #252525;
	text-decoration: none;
	font-size: 1em;
}

.affiliates table td a:hover {
	cursor: pointer;
}

.profile_pic {
	margin: 0 auto;
}

	/*========================================
	=            ACCOUNT SETTINGS            =
	========================================*/

	.account_settings .all_settings > div {
		border-bottom: 2px solid #E6E6E6;
		padding-top: 5px;
		padding-bottom: 5px;
		cursor: pointer;
	}

	.account_settings .all_settings h2 {
		/* text-align: right; */
		font-weight: 700;
		font-size: 1.5em;
	}

	.account_settings .all_settings p {
		line-height: 1;
	}

	.account_setting_btn{
		cursor: pointer;
		padding: 5px 0;
		background-color: #0096A7;
		width:100%;
		color:#fff;
		font-size:1.2em;
	}
	.privacy_content:last-child{
		border-bottom:none;
	}
	.privacy_setting{
		display:flex;
		justify-content: space-between;
		align-items: center;
	}
	.privacy_setting p{
		margin-bottom:0px;
	}


	/*========================================
	=                 Switches               =
	========================================*/


	/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
  flex-shrink: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50px;
  border: 1px solid #838383;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 5px;
  bottom: 4px;
  background-color: #838383;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: transparent;
  border: 1px solid #FF281B;
}

input:focus + .slider {
  box-shadow: 0 0 1px transparent;
}

input:checked + .slider:before {
  background-color: #fff;
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


	/*========================================
	=                   CMS                  =
	========================================*/

/* .cms_dashboard th, NOTE */
.cms_events th{
	text-align: right;
}

.cms_dashboard th:last-child, .cms_events th:last-child {
	text-align: center;
}

.cms_dashboard .scope_filters {
	margin-top: 15px;
	margin-bottom: 10px;
}

.refund_commission p {
	font-size: 1em;
}

.cms_events td a,
.cms_users td a {
	color: #252525;
	font-size: 1em;
}

.cms_events select,
.cms_dashboard select {
	background-color: transparent;
}

.cms_events .event_status p {
	font-size: 1em;
}

.cms_events .event_sub_status {
	color: #a4a4a4;
}

.cms_payment_form .save_settings{
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin: 0px;
  padding: 5px 0;
  z-index: 1;
  background-color: #0096A7;
}

.cms_web_hooks .save_settings {
	position: fixed;
	bottom: 0px;
	right: 0px;
	margin: 0px;
	padding: 5px 0;
	z-index: 1;
	background-color: #0096A7;
}

.cms_payment_form .save_settings button,
.cms_web_hooks .save_settings button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5em;
}

.cms_web_hooks input[type="text"]:placeholder {
	text-align: left;
}
.cms_web_hooks input[type="text"]:-moz-placeholder {
    text-align: left;
}
.cms_web_hooks input[type="text"]:-ms-input-placeholder {
    text-align: left;
}
.cms_web_hooks input[type="text"]::-webkit-input-placeholder {
    text-align: left;
}

.cms_web_hooks .missing_params_modal ul {
	list-style: inside;
}

	/*========================================
	=                TERMS PAGE              =
	========================================*/

.terms a {
	color: #252525;
	font-size: 1em;

}

.terms .salesman_info {
	line-height: 1.44;
	margin-top: 0pt;
	margin-bottom: 7pt;
	text-align: center;
}

.terms p {
	line-height:1.44;
	margin-top:0pt;
	margin-bottom:7pt;
}

.terms span {
	font-size: 11pt;
  font-family: "Times New Roman";
}

.terms span.emphasis {
	font-weight: 700;
}

.terms span.underline {
	text-decoration-line: underline;
}

.terms ul {
	margin-top:0pt;
	margin-bottom:0pt;
}
	/*========================================
	=              MEDIA QUERIES             =
	========================================*/

@media screen and (max-width: 414px) {

	.top-section{
		display:block;
	}
	.top-section div{
		text-align: center;
	}
	.back_to p {
		text-align: center;
	}
	.event-settings-title{
		text-align: center;
	}

	.fb-text {
		font-size: 0.9em;
		font-weight: 700;
	}

	.fb-text-connect {
		font-size: 0.8em;
		font-weight: 700;
	}

	.facebook {
		line-height: 1.1;
	}

	.facebook .round img {
		width: 35px;
		height: 35px;
	}

	.cms_dashboard .search {
		margin-top: 5px;
	}

	.icon {
		right: 0px;
		bottom: -45px;
	}

	.hide-xs{
		display: none;
	}

	.more_info_btn{
		text-align: right;
	}

	#participant_sub_info{
	padding: 5% 0 0 0;
	}


	.participants .sum_tabs > div {
		min-height:85px;
	}


	div[class^="more_details_"]{
		margin: 10% 0 0 0;
	}



	.sub_info_btn{
		padding-top: 10%;
	}


	.numb-input {
		width: 60px;
	}


}
@media screen and (max-width: 414px) {
	.pl-10 {
		padding-left: 10px;
	}
}
/* Landscape for IpadPRO*/

@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
	#wrapper {
		width: 100% !important;
	}

}

/* Portrait for IpadPRO*/
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
	#wrapper {
		width: 100% !important;
	}

}

@media screen and (min-width: 990px) {

	/*	.container {
		width: 100%;
	}*/

	body {
		line-height: 1.4;
	}

	#wrapper {
		-webkit-box-shadow: none;
		        box-shadow: none;
		width: 100%;
	}

	#user_interface .close-menu {
		padding-top: 0;
	}
	#user_interface .close-menu img {
		float: left;
		width: 1.4em;
		cursor: pointer;
	}

	.facebook {
		width: 50%;
		display: inline;
		margin-top: 5px;
		font-size: 1.5em;

	}

	.facebook .col-xs-4.text-left {
		/*width: 30%;*/
		display: inline;
		/*float:none;*/
		right: 0;
	}

	.facebook .col-xs-8 {
		/*width: 60%;*/
		display: inline;
		/*float:none;*/
		left: 0;
	}

	.events .social {
		margin-bottom: 13px;
	}
	.events .social > div {
		cursor:pointer;
	}

	.events .fb-text {
		font-size: 1em;
	}
		.events .fb-text-connect {
		font-size: 0.8em;
	}

	.events .actions {
		font-size: 1em;
	}

	#master-container {
		padding: 0px;
		/*width: 40%;*/
	}
/*
	.preview_image {
		float: left;
	}*/

	.salesman_info {
		/* display: none; */
	    position:fixed;
	    top: 50%;
	    left: 50%;
	    width:500px;
	    /*height:250px;*/
	    margin-top: -125px; /*set to a negative number 1/2 of your height*/
	    margin-left: -250px; /*set to a negative number 1/2 of your width*/
	    border: 1px solid #ccc;
	    background-color: #f3f3f3;
	    padding: 15px 20px;
	}
	.signin,
	.signup {
		width: 60%;
		margin: 25px auto 0px;

	}

}

.modal-backdrop, .modal-backdrop.in{
	display: none;
}

/* For label of custom input that have a in it... */
.white a {
    color: white !important;
}

.item-error a {
	color: red !important;
}


.full-width {
	width: 100%;
}
.event_links a img{
	max-width: 30px;
	max-height: 30px;
}

.iti.iti--allow-dropdown{
	width: 100%;
}
.iti__flag-box, .iti__country-name {
  color: black;
}
.ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li{
	background-color: #0096A7 !important;
}

/*--------EventParticipantInfoModalComponent--------*/
.attendee-modal {
  min-width: 85vw;
  min-height: 85vh;
}
.attendee-modal .mat-mdc-dialog-container {
  background: #202226;
  color: #fff;
}
.attendee-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: #202226;
  box-shadow: 7px 16px 35px rgba(255, 100, 101, 0.09);
  color: #fff;
  border-radius: 15px;
}
.attendee-modal .mat-expansion-panel-header-title {
  margin-left: 16px;
}

.attendee-modal  .mat-expansion-panel {
  background: transparent;
  color: #fff;
}
.attendee-modal .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.attendee-modal .mat-expansion-panel-header {
  margin: 0 auto;
  justify-content: center;
}
.attendee-modal .mat-expansion-panel-body {
   padding: 0;
 }
.attendee-modal .mat-content {
  flex: initial;
}
.attendee-modal .mat-expansion-panel-header-description,
.attendee-modal .mat-expansion-panel-header-title,
.attendee-modal .mat-expansion-indicator::after {
  color: #FF6465;
}
.attendee-modal .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: transparent;
}

.custom-fields-modal .mat-mdc-dialog-container {
  background: linear-gradient(180deg, #242831 0%, rgba(0, 0, 0, 0.83) 74%);
}
.custom-fields-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: linear-gradient(180deg, #242831 0%, rgba(0, 0, 0, 0.83) 74%);
}

.ngx-pagination .current {
  background:  #FF6465 !important;
  border-radius: 50px !important;
}

.new-ui .ngx-pagination {
  outline: none !important;
}

.new-ui .ngx-pagination {
  margin: 0 !important;
}

.new-ui  .ngx-pagination a,
.new-ui  .ngx-pagination button {
  color: #fff !important;
}

.new-ui .ngx-pagination a:hover {
  background: transparent !important;
}
/*Prevent dragging links and text*/
.dragging .event-data,
.dragging .event-wrap,
.dragging .card,
.dragging a,
.dragging .producer{
  user-select: none;
}
.dragging .producer a,
.dragging .sidenav-menu-link,
.dragging .event-wrap {
  pointer-events: none;
}
