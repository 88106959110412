/*============================
 Spacing Adjusting Classes:
===========================*/

/*margin-top 0-50*/
.mt0 {margin-top: 0;}
.mt5 {margin-top: 5px;}
.mt10 {margin-top: 10px;}
.mt15 {margin-top: 15px;}
.mt20 {margin-top: 20px;}
.mt25 {margin-top: 25px;}
.mt30 {margin-top: 30px;}
.mt35 {margin-top: 35px;}
.mt40 {margin-top: 40px;}
.mt50 {margin-top: 50px;}

/*margin-bottom 0-50*/
.mb0 {margin-bottom: 0;}
.mb5 {margin-bottom: 5px;}
.mb10 {margin-bottom: 10px;}
.mb15 {margin-bottom: 15px;}
.mb20 {margin-bottom: 20px;}
.mb25 {margin-bottom: 25px;}
.mb30 {margin-bottom: 30px;}
.mb35 {margin-bottom: 35px;}
.mb40 {margin-bottom: 40px;}
.mb50 {margin-bottom: 50px;}



/*padding-top 0-50*/
.pt0 {padding-top: 0;}
.pt5 {padding-top: 5px;}
.pt10 {padding-top: 10px;}
.pt15 {padding-top: 15px;}
.pt20 {padding-top: 20px;}
.pt25 {padding-top: 25px;}
.pt30 {padding-top: 30px;}
.pt35 {padding-top: 35px;}
.pt40 {padding-top: 40px;}
.pt45 {padding-top: 45px;}
.pt50 {padding-top: 50px;}
.pt75 {padding-top: 75px;}

/*padding-bottom 0-50*/
.pb0 {padding-bottom: 0;}
.pb5 {padding-bottom: 5px;}
.pb10 {padding-bottom: 10px;}
.pb15 {padding-bottom: 15px;}
.pb20 {padding-bottom: 20px;}
.pb25 {padding-bottom: 25px;}
.pb30 {padding-bottom: 30px;}
.pb33 {padding-bottom: 33px;}
.pb34 {padding-bottom: 34px;}
.pb35 {padding-bottom: 35px;}
.pb40 {padding-bottom: 40px;}
.pb50 {padding-bottom: 50px;}

/*Rest the margin and padding to 0 value*/
.mi0 {margin: 0 !important;}
.pi0 {padding: 0 !important;}
.m0 {margin: 0;}
.p0 {padding: 0;}

/*Padding for all sides*/
.p5 {padding: 5px;}
.p10 {padding: 10px;}
.p15 {padding: 15px;}
.p20 {padding: 20px;}
.p25 {padding: 25px;}
.p30 {padding: 30px;}
.p35 {padding: 35px;}
.p40 {padding: 40px;}
.p45 {padding: 45px;}
.p50 {padding: 50px;}

/*Margin for all sides*/
.m5 {margin: 5px;}
.m10 {margin: 10px;}
.m15 {margin: 15px;}
.m20 {margin: 20px;}
.m25 {margin: 25px;}
.m30 {margin: 30px;}
.m35 {margin: 35px;}
.m40 {margin: 40px;}
.m45 {margin: 45px;}
.m50 {margin: 50px;}
