:where(html[dir='rtl']) {
  *:not(.no-rtl) {
    direction: rtl;
  }

  /* Override header direction */

  app-header,
  app-header > header,
  app-header > header > .header-wrap,
  app-header > header > .header-wrap > .header-items {
    direction: ltr;
  }

  /* Override footer direction */

  app-footer,
  app-footer > footer {
    direction: ltr;
  }

  @media screen and (min-width: 768px) {
    app-footer > footer > .footer-wrapper,
    app-footer > footer > .footer-wrapper > .footer-item-logo,
    app-footer > footer > .footer-wrapper > .footer-item-logo > .footer-txt,
    app-footer > footer > .footer-wrapper > .footer-item.apps > .footer-title,
    app-footer > footer > .footer-wrapper > .footer-item-logo > .social {
      direction: ltr;
    }
  }

  /* Override mat-spinner direction */
  mat-spinner mat-spinner .mdc-circular-progress__circle-clipper,
  mat-spinner .mdc-circular-progress__circle-right,
  mat-spinner .mdc-circular-progress__indeterminate-container,
  mat-spinner .mdc-circular-progress__circle-left,
  mat-spinner .mdc-circular-progress__spinner-layer {
    direction: ltr;
  }

  /* TODO: Override app-category-sidenav direction */
  /* :where(htm[dir="rtl"][lang="he"])wseUniversalLink app-category-sidenav,
.sidenav-categories rtl {
	direction: ltr;
} */

  .row > * {
    float: right;
  }

  .quill-wrapper .sub-quill-wrapper:not(.quill-he) * {
    direction: ltr !important;
  }
  h2 {
    text-align: right;
  }
  ::-webkit-input-placeholder {
    text-align: right;
  }
  :-moz-placeholder {
    text-align: right;
  }
  ::-moz-placeholder {
    text-align: right;
  }
  :-ms-input-placeholder {
    text-align: right;
  }
  .eventInfo th,
  .cms_web_hooks th,
  .cms_users th,
  .cms_events th,
  .cms_dashboard th {
    text-align: right;
  }
  .cms_payment_form .actions-box > div:first-child {
    border-left: 1px solid #d2d2d2;
  }
  .events .fa-position,
  .open_seller_coupons .fa-position,
  .salesmen .fa-position,
  .participants .fa-position,
  .cms_web_hooks .fa-position,
  .dashboard .fa-position {
    float: left;
  }
  .close-form {
    float: right;
  }
  .open_seller_coupons table th {
    text-align: right;
  }
  .mutate-float-left {
    float: left;
  }
  .mutate-float-right {
    float: right;
  }
  .mutate-border-left {
    border-left: 1px solid #d7d7d7;
  }
  .mutate-text-right {
    text-align: right;
  }
  .settings-container mat-expansion-panel-header {
    padding: 0 0 0 24px;
  }
  .settings-container .btn-group > .btn {
    float: left;
  }
  /*margin-left 0-50*/
  .ml0 {
    margin-left: 0;
  }
  .ml5 {
    margin-left: 5px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .ml15 {
    margin-left: 15px;
  }
  .ml20 {
    margin-left: 20px;
  }
  .ml25 {
    margin-left: 25px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .ml35 {
    margin-left: 35px;
  }
  .ml40 {
    margin-left: 40px;
  }
  .ml50 {
    margin-left: 50px;
  }

  /*margin-top 0-50*/
  .mr0 {
    margin-right: 0;
  }
  .mr3 {
    margin-right: 3px;
  }
  .mr5 {
    margin-right: 5px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mr15 {
    margin-right: 15px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .mr25 {
    margin-right: 25px;
  }
  .mr30 {
    margin-right: 30px;
  }
  .mr35 {
    margin-right: 35px;
  }
  .mr40 {
    margin-right: 40px;
  }
  .mr50 {
    margin-right: 50px;
  }

  /*padding-left 0-50*/
  .pl0 {
    padding-left: 0;
  }
  .pl5 {
    padding-left: 5px;
  }
  .pl10 {
    padding-left: 10px;
  }
  .pl15 {
    padding-left: 15px;
  }
  .pl20 {
    padding-left: 20px;
  }
  .pl25 {
    padding-left: 25px;
  }
  .pl30 {
    padding-left: 30px;
  }
  .pl35 {
    padding-left: 35px;
  }
  .pl40 {
    padding-left: 40px;
  }
  .pl50 {
    padding-left: 50px;
  }

  /*padding-right 0-50*/
  .pr0 {
    padding-right: 0;
  }
  .pr5 {
    padding-right: 5px;
  }
  .pr10 {
    padding-right: 10px;
  }
  .pr15 {
    padding-right: 15px;
  }
  .pr20 {
    padding-right: 20px;
  }
  .pr25 {
    padding-right: 25px;
  }
  .pr30 {
    padding-right: 30px;
  }
  .pr35 {
    padding-right: 35px;
  }
  .pr40 {
    padding-right: 40px;
  }
  .pr50 {
    padding-right: 50px;
  }

  .fb_icons .round {
    margin-right: -15px;
    display: inline;
  }

  .fb_icons .round:first-child {
    margin-right: 0px;
  }

  .terms * {
    direction: rtl;
  }

  .terms p,
  .terms span {
    text-align: right;
  }

  .bg_color h4 {
    text-align: right;
  }

  // intl-tel-input RTL overrides

  .iti {

    &.iti--allow-dropdown {
      direction: ltr !important;

      input {
        direction: ltr !important;
      }
    }
    .iti__flag-container, .iti__selected-flag, .iti__country {
      direction: ltr !important;
    }

    .iti__country-list {
      left: 0;
    }
    .iti__country {
      text-align: start;
    }
  }


  td.table-cell-phone-number {
    direction: ltr;
    text-align: right;
  }

  .ngx-dropdown-container button span {
    float: right !important;
  }
  .ngx-dropdown-container button .nsdicon-angle-down {
    float: left !important;
  }
  .ngx-dropdown-list-container ul.available-items,
  .ngx-dropdown-list-container ul.selected-items {
    direction: ltr !important;
  }
  .ngx-dropdown-container .ngx-dropdown-list-container .search-container label {
    right: 0 !important;
  }

  //  Override phone/email/password
  #phoneInput, [type="email"], [type="password"], [type="phone"], [formcontrolname="user_email"], [formControlName="instagram_url"] {
	direction: ltr !important;
  }
  
  .new-placeholder {
    text-align: right;
  }

  .rtl .iti--allow-dropdown input {
    text-align: left;
  }
  .web-otp-input {
    direction: ltr;
  }
  .icon-right:after {
    transform: rotate(180deg);
  }
}

html[dir=rtl] {
  .ticket-photo-wrapper {
    display: flex;
    justify-content: center;
    min-width: 5px;
    margin-top: 5px;
    border: 1px solid #ffffff2e;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
}
