/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~vanilla-cookieconsent/dist/cookieconsent.css';
@import "~quill/dist/quill.snow.css";

qrcode > img {
	margin: 0 auto;
}

.my-sidenav-content > #master-container > .full-screen {
	width: 100%;
}
/*Override cookieconsent */
#cc-main {
  /** Change font **/
  --cc-font-family: 'Open Sans Hebrew', sans-serif;
  --cc-bg: #080416;

  --cc-btn-primary-bg: #ffffff;
  --cc-btn-primary-color: #000;
  --cc-btn-primary-hover-bg: #ccd4d8;
  --cc-btn-primary-hover-color: #000;

  --cc-btn-secondary-bg: #16112B;
  --cc-btn-secondary-color: var(--cc-primary-color);
  --cc-btn-secondary-border-color: #252729;
  --cc-btn-secondary-hover-bg: #252045;
  --cc-btn-secondary-hover-color: #fff;
  --cc-btn-secondary-hover-border-color: #252729;

  /** Make the buttons a bit rounder **/
  --cc-btn-border-radius: 8px;

  --cc-separator-border-color: #252729;

  --cc-footer-border-color: #212529;
  --cc-footer-bg: #000;
}
#cc-main .cm--bar.cm--inline .cm__body, #cc-main .cm--bar.cm--inline .cm__links {
  max-width: 1495px;
}
#cc-main .cm,
#cc-main .pm {
  border: 1px solid var(--cc-separator-border-color);
}
#cc-main .cm--bar .cm__btn {
  padding-left: 1em;
  padding-right: 1em;
}
#cc-main .cm__btn+.cm__btn, #cc-main .cm__btn-group+.cm__btn-group {
  margin-top: 10px;
}
#cc-main .cm--inline .cm__btn+.cm__btn {
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  #cc-main .cm__btns {
    padding: 0.5rem 0.7rem;
  }
  #cc-main .cm__title+.cm__desc {
    margin-top: 0.5em;
  }
  #cc-main .cm__btn {
    min-height: 32px;
  }
  #cc-main .cm__btn+.cm__btn {
    margin-top: 0 !important;
  }
  #cc-main .cm--inline .cm__btn+.cm__btn {
    margin-left: .575rem !important;
  }
  #cc-main .cm__btn-group+.cm__btn-group {
    margin-top: .575rem !important;
  }
  #cc-main .cm__title {
    font-size: 0.8em;
  }
  #cc-main .cm__desc {
    font-size: 0.7em;
  }
  #cc-main .cm__footer {
    padding: .1em 0 .2em;
  }
  #cc-main .cm__btn-group {
    flex-direction: row !important;
  }
}
