@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 300;
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-LightItalic.eot);
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-LightItalic.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-LightItalic.woff2) format('woff2'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-LightItalic.woff) format('woff'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-LightItalic.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Light.eot);
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Light.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Light.woff2) format('woff2'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Light.woff) format('woff'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Light.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 400;
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Italic.eot);
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Italic.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Italic.woff2) format('woff2'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Italic.woff) format('woff'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Italic.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Regular.eot);
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Regular.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Regular.woff2) format('woff2'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Regular.woff) format('woff'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 700;
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-BoldItalic.eot);
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-BoldItalic.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-BoldItalic.woff2) format('woff2'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-BoldItalic.woff) format('woff'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-BoldItalic.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Bold.eot);
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Bold.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Bold.woff2) format('woff2'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Bold.woff) format('woff'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-Bold.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 800;
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBoldItalic.eot);
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBoldItalic.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBoldItalic.woff2) format('woff2'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBoldItalic.woff) format('woff'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBoldItalic.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 800;
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.eot);
  src: url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.woff2) format('woff2'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.woff) format('woff'),
  url(/assets/fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 100;
  src: url(/assets/fonts/NeueHaasGroteskDisplayPro/NeueHaasDisplayXXThin.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 200;
  src: url(/assets/fonts/NeueHaasGroteskDisplayPro/NeueHaasDisplayXThin.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/NeueHaasGroteskDisplayPro/NeueHaasDisplayThin.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/NeueHaasGroteskDisplayPro/NeueHaasDisplayLight.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 500;
  src: url(/assets/fonts/NeueHaasGroteskDisplayPro/NeueHaasDisplayRoman.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  src: url(/assets/fonts/NeueHaasGroteskDisplayPro/NeueHaasDisplayMedium.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/NeueHaasGroteskDisplayPro/NeueHaasDisplayBold.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 900;
  src: url(/assets/fonts/NeueHaasGroteskDisplayPro/NeueHaasDisplayBlack.ttf) format('truetype');
  font-display: swap;
}
