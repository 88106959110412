@import url(font-awesome.min.css);

/* Reset */

	* {
		margin: 0;
		padding: 0;
		border: 0;
		/*font-family: 'Assistant', sans-serif;*/
	}

	/* Box Model */

	*, *:before, *:after {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	article, aside, footer, header, nav, section {
		display: block;
	}


  body {
    /*line-height: 1;*/
    -webkit-text-size-adjust: none;
    -ms-overflow-style: scrollbar;
    /*background: url(/assets/images/menu_creat_event.png) no-repeat center fixed;*/
    /*background-size: cover;*/
    background-color: #080416; /* #fff; */
    color: #FFF;
    line-height: 2.375;
  }

	@-ms-viewport {
		width: device-width;
	}

	ul {
		list-style: none;
	}

/* Grid */

	.row {
		border-bottom: solid 1px transparent;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row > * {
		/* float: left; NOTE*/
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row:after, .row:before {
		content: '';
		display: block;
		clear: both;
		height: 0;
	}



/* Basic */

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

	@media screen and (max-width: 768px){

		html {
			font-size: 12pt;
		}

	}
	@media screen and (max-width: 414px){

		html {
			font-size: 10pt;
		}

	}
	@media screen and (max-width: 736px) and (orientation: landscape) {
		html {
			font-size: 11pt;
		}
	}

	a {

		text-decoration: none;
		color: #252525;
		font-size: 1.5rem;
		background: transparent;
	}

	a:hover {
		border-bottom-color: transparent;
	}

	p {
		text-align: justify;
		/*font-family: 'Assistant', sans-serif;*/
		font-size: 1.2em;
		line-height: 1.5;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 900;
		line-height: 1.5;
		letter-spacing: 0.075em;
		margin: 5px 0px;
		color: #252525;
	}

	h1 {
		font-size: 2.3em;
	}

	h2 {
		/*font-family: 'Assistant', sans-serif;*/
		font-size: 1.9em;
		/* text-align: center; NOTE*/
	}

	h3 {
		font-size: 1.7em;
	}

/* Image */

	.image {
		border: 0;
		display: inline-block;
		position: relative;
	}

	.image img {
		display: block;
	}

	.image.fit {
		display: block;
		margin: 5px 0;
		width: 100%;
		/*box-shadow: 0 0.2rem 0.9rem 0 rgba(30, 37, 45, 0.25);*/

	}

	.image.fit:first-child {
		margin-top: 0;
		/*background-color: #212931;*/
	}

	.image.fit img {
		width: 100%;
	}

	a.image {
		overflow: hidden;
	}

	a.image img {
		-webkit-transition: -webkit-transform 0.2s ease-out;
		transition: -webkit-transform 0.2s ease-out;
		transition: transform 0.2s ease-out;
		transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
	}

	a.image:hover img {
		-webkit-transform: scale(1.05);
		transform: scale(1.05);
	}

/* List */

	ul {
		list-style: disc;
		margin: 0 0 2rem 0;
		padding-left: 1rem;
	}

	ul li {
		padding-left: 0.5rem;
	}

/* Footer */

  .options-popup .options-popup-content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

	@media screen and (max-width: 980px) {
		body.is-navPanel-visible #wrapper {
			opacity: 0.5;
		}
		a {
      text-decoration: none;
      color: #252525;
      font-size: 1rem;
      background: transparent;
		}
	}

  .event_description p {
    font-size: 0.8em;
  }

  .full_purchase{
    background-color: #313238;
    width:100%;
    height: 100vh;

  }



  .divider{
    border: 1px solid #ececec;
    margin-bottom: 10px;
  }
  .cursor-pointer {
    cursor: pointer;
  }


.close-nav {
  position: fixed;
  left: 4rem;
  top: 4rem;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: block;
  background-image: url("/assets/images/svg-icons/left_angle_arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: contain;
}

/* ------------------Login Modal---------------- */

.loginModal {
	padding: 0;
	background: rgba(36, 40, 49, 0.95);
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.55);
	border-radius: 25px;
}

/* ------------------Search and filter---------------- */

.filters {
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
  position: sticky;
  top: 70px;
  background-color: rgba(36,38,43,.6);
  z-index: 100;
  min-height: 56px;
  align-items: flex-start;
}

.categories-filter, .search-filter {
  min-height: 40px;
  display: flex;
}

.categories-filter {
  flex: 1;
}

.search-filter {
  width: 35%;
  max-width: 550px;
}

.filter-btn, .search-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 25px;
  height: 25px;
  border: none;
  outline: none;
  top: 15px;
}

.filter-btn svg path, .search-btn svg path {
  transition: all .25s;
}

.filter-btn.active svg path, .search-btn.active svg path{
  fill: #ff6465;
}

.search-input {
  width: 100%;
  background: #2C303B;
  border: 1px solid #82FFCA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 35px;
  padding: 6px 44px 6px 15px;
  font-size: 15px;
  color: #fff;
  line-height: 1;
  transition: all .25s;
}

.search-input:focus {
  border-color: #FF6465;
}

.search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-image: url("/assets/images/svg-icons/plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.search-btn {
  right: 10px;
}

.filter-btn {
  left: 0;
}

.rtl .filter-btn {
  left: initial;
  right: 0;
}

.rtl .search-btn {
  right: initial;
  left: 15px;
}

.rtl .search-input {
  padding: 6px 15px 6px 44px;
}

.x-scroll-hidden {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
}

.x-scroll-hidden::-webkit-scrollbar {
  display: none;
}

/*Desktop ql-video  minHeight as wrapp-content-2 width*/
.event_description.ql-editor .ql-video {
  min-height: calc(30vw - 30px);
  width: 100%;
}
/*Edit Event page*/
.settings-container .ql-editor .ql-video {
  min-height: 350px;
}

@media screen and (max-width: 768px) {
  .filters {
    flex-direction: column;
  }
  .search-filter {
    position: absolute;
    top: 8px;
    left: 32px;
    width: calc(100% - 64px);
  }
  .search-input,
  .rtl .search-input {
    padding: 6px 15px;
  }
  .categories-filter {
    justify-content: center;
    margin-left: 34px;
    width: calc(100% - 75px);
  }
  .search-btn {
    right: 0;
  }
  .close-nav {
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    left: 2rem;
    top: 2rem;
  }

  .rtl .search-btn {
    left: 0;
  }

  .rtl .categories-filter {
    margin-left: 0;
    margin-right: 34px;
  }
  /*Mobile ql-video*/
  .event_description.ql-editor .ql-video {
    min-height: calc(90vw - 30px);
  }

  .waited-terms-chk.mat-mdc-checkbox.mat-accent {
    position: fixed;
    bottom: 100px;
    /*bottom: 80px; // 15px + 68px(button)*/
  }

}
.scanned-select .mat-mdc-option.mdc-list-item--disabled {
  opacity: 0.8;
}

/*---------New form styles---------*/

.new-form .label-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
}

.new-form .form-control {
  width: 100%;
  height: auto;
  background-color: transparent;
  border: 1px solid #FF281B;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 194px;
  padding: 12px 50px;
  font-size: 15px;
  color: #fff;
}

.new-form .new-form-btn{
  width: 100%;
  margin: 0;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 25px;
  color: #FFFFFF;
  cursor: pointer;
  height: 55px;
  background: #2C303B;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  text-transform: uppercase;
  line-height: 1;
}

.disable {
  background: rgba(105, 108, 114, 0.6);
  cursor: not-allowed;
}

.new-form input.ng-invalid.ng-touched {
  border: 1px solid red;
}

.new-form .migrated-user a {
  color: #252525;
  font-size: 1em;
  text-decoration: underline;
}

.new-form .migrated-user p {
  text-align: right;
}

.new-form .disable {
  background: rgba(105, 108, 114, 0.6);
}

.new-form input.ng-invalid.ng-touched {
  border: 1px solid red;
}

.new-form.center * {
  text-align: center;
}

.new-form input::-webkit-outer-spin-button,
.new-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.new-form input[type=number] {
  -moz-appearance: textfield;
}

/*--------New form select styles--------*/

.new-form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/assets/images/svg-icons/down_angle_arrow.svg');
  background-repeat: no-repeat;
  background-position: right 20px top 50%;
  background-size: .65em auto;
  text-align-last:center;
}
.new-form select::-ms-expand {
  display: none;
}
.new-form select:focus {
  outline: none;
}
.new-form select option {
  font-weight:normal;
  background: #242831;
  color: white;
}
/*--------areUSUreModal--------*/
.areUSUreModal .mat-mdc-dialog-container {
  background: #fff;
}
.areUSUreModal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: #fff;
}
/*--------MyTicketsModal--------*/
.my-tickets-modal .mat-mdc-dialog-container {
  background: linear-gradient(180deg, #242831 0%, rgba(0, 0, 0, 0.95) 74%);
}
.my-tickets-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: linear-gradient(180deg, #242831 0%, rgba(0, 0, 0, 0.95) 74%);
}
.my-tickets-modal .mat-mdc-dialog-content {
  max-height: initial;
}
/*--------NftSuccessModalComponent--------*/
.nft-success-modal .mat-mdc-dialog-container {
  background: #22262F;
  border-radius: 20px;
  color: #fff;
  padding: 0;
  margin: 0;
}
.nft-success-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: #22262F;
  border-radius: 20px;
}
.nft-success-modal .mat-mdc-dialog-container .mdc-dialog__surface::-webkit-scrollbar {
  width: 2px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
  scrollbar-width: none;  /* Firefox */
  display: none;  /* Safari and Chrome */
}
.nft-success-modal .mat-mdc-dialog-content {
  max-height: initial;
}
.nft-success-modal .mat-mdc-dialog-container::-webkit-scrollbar {
  width: 0px;
}
.nft-success-modal .mat-mdc-dialog-container::-webkit-scrollbar-track {
  background: #171A20;
}

.mint-modal-bc {
  background-color: rgba(0, 0, 0, 0.9);
}



/*--------TicketImgModalComponent--------*/
.ticket-img-modal .mat-mdc-dialog-container {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}
.ticket-img-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: transparent;
}
/*--------BankWarningModalComponent--------*/
.bank-warning-modal .mat-mdc-dialog-container {
  background: transparent;
  box-shadow: none;
  padding: 0;
  border-radius: 20px;
  max-height: 90vh;
}
.bank-warning-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: transparent;
}
/*--------BankInfoModalComponent--------*/
.bank-info-modal .mat-mdc-dialog-container {
  background: #252045;
  border-radius: 16px;
  color: #fff;
  margin: 0;
  max-height: 100vh;
}
.bank-info-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: #252045;
  border-radius: 16px;
  margin: 0;
  max-height: 100vh;
}
/*--------PasscodeModalComponent--------*/
.passcode-dialog .mat-mdc-dialog-container .mdc-dialog__surface{
  background: #22262F;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.65);
  border-radius: 30px;
  margin: 0;
}
/*--------TransferTicketModalComponent--------*/
.transfer-ticket-dialog .mat-mdc-dialog-container .mdc-dialog__surface{
  background: #22262F;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.65);
  border-radius: 30px;
  margin: 0;
}
.transfer-ticket-dialog .iti__country-list {
  background: #22262F;
}
/*--------ApproveWaitingListModalComponent--------*/
.approve-waiting-list-dialog .mat-mdc-dialog-container .mdc-dialog__surface{
  background: #202226;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.65);
  border-radius: 15px;
  margin: 0;
}
/*--------CreateEdit Event terms--------*/
.terms .mat-mdc-dialog-container .mdc-dialog__surface {
  background: #24262b;
}
/*--------BankInfoModalComponent--------*/
.fans-tickets-modal .mat-mdc-dialog-container {
  background: #080416;
  padding: 15px;
}
.fans-tickets-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  background: #080416;
  padding: 15px;
}
.bank-info-modal .mat-mdc-dialog-container::-webkit-scrollbar {
  width: 5px;  /* Remove scrollbar space */
  background: transparent;
}
.bank-info-modal .mat-mdc-dialog-container::-webkit-scrollbar-thumb {
  background: #16112B;
  border-radius: 4px;
}
/*--------ContactProducerComponent--------*/
.contactProducerModal .mat-mdc-dialog-container {
  border-radius: 21px;
}
.contactProducerModal .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
}
/*--------CMS black-list--------*/
.black-list-modal .mdc-dialog__surface {
  padding: 24px;
}

/*--------SvixSettingsComponent--------*/
.svix-settings-modal .mat-mdc-dialog-container .mdc-dialog__surface{
  background: #16112B;
  border-radius: 4px;
  color: #fff;
}
/*--------New form radio and checkbox styles--------*/

.radio-label,
.check-label {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.radio-label input,
.check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-label .checkmark,
.check-label .checkmark {
  width: 18px;
  height: 18px;
  border: 1px solid #FF281B;
  position: relative;
  margin: 0 10px;
}

.radio-label .checkmark:after,
.check-label .checkmark:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  background-image: url("../images/svg-icons/check_white.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all .25s;
}
.radio-label input:checked ~ .checkmark:after,
.check-label input:checked ~ .checkmark:after{
  opacity: 1;
}

.radio-label .check-payment,
.check-label .check-payment {
  width: 36px;
  height: 36px;
  border: 1px solid #FF6465;
  position: relative;
  margin: 0 20px 0 0;
}

.radio-label .check-payment:after,
.check-label .check-payment:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  background-image: url("../images/svg-icons/check_white.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all .25s;
}
.radio-label input:checked ~ .check-payment:after,
.check-label input:checked ~ .check-payment:after{
  opacity: 1;
}
/*app-home-carousel START*/
.carousel-tile .tile{box-shadow: none !important;}
@media screen and (min-width: 768px) {
  .home-carousel .ngucarousel {
    padding-bottom: 40px;
  }
}
/*app-home-carousel END*/
/*app-autocomplete-filter-events START*/
.events-filter-results {
  background: #252045;
  border-radius: 8px;
  margin-top: 10px;
}
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.events-filter-results.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #111023;
}
/*app-autocomplete-filter-events END*/
/*app-language-select START*/
.lang-mat-select {
  background: #16112B;
  border-radius: 8px;
}
.lang-mat-select .mat-mdc-option {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.lang-mat-select  .mat-option-text {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: #FFFFFF;
}
.lang-mat-select .mat-mdc-select-value {
  color: #fff;
}
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.lang-mat-select .mat-option-text img {
  max-width: 15px;
  display: inline-block;
}
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.lang-mat-select .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: #fff;
}
.lang-mat-select .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
  background: #322956;
}
.mdc-circular-progress__circle-clipper,
.mdc-circular-progress__spinner-layer {
  direction: ltr;
}
 .mat-mdc-option:hover:not(.mat-option-disabled), .lang-mat-select .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(50,41,86,.7);
}
.mat-spotify .mat-mdc-option:hover:not(.mat-option-disabled) {
  background: #353840;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.lang-mat-select .mat-select-arrow-wrapper,
.lang-mat-select .mat-form-field-underline {
  display: none;
  opacity: 0;
}
.waited-terms-chk {
  padding: 0 15px 10px;
}
.waited-terms-chk a{
  color: #fff;
  text-decoration: underline;
}
.waited-terms-chk label{
  color: #A29BB1;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
/*FOCUS*/
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #fff;
  background-color: #3b3b3b;
}
/*HOVER*/
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #fff;
  background-color: #3b3b3b;
}
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #fff;
  background-color: #3b3b3b;
}
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: #cccccc;
  background-color: #3b3b3b;
}
.waited-terms-chk.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: white;
  background-color: #555555;
}
.waited-terms-chk.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
  background: #ffffff;
}
/*.mat-mdc-dialog-container div.mdc-dialog__surface {*/
/*  background-color: #24262b;*/
/*}*/
/*Dashboard Tickets Progress Bar */
.ticket-progress .mat-mdc-progress-bar {
  height: 18px;
  border-radius: 10px;
}
.ticket-progress .mat-mdc-progress-bar .mdc-linear-progress__bar-inner {
  border-top-width: 18px;
}
.ticket-progress .mdc-linear-progress__bar-inner {
  border-color: #82FFCA;
}
.ticket-progress .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(255,255,255,1);
}
/*Dashboard Tickets Progress Bar END*/

/*app-language-select END*/
.my-tickets-modal-body .scanned .qrcode {
  opacity: 0.1;
  filter: blur(4px);
}
.barcode .qrcode {
  text-align: center;
}
.event_description.ql-editor a {
  color: #ff6465;
}
.ticketMenu.mat-mdc-menu-panel {
  background-color: #322956;
}
.ticketMenu .mat-mdc-menu-item .mdc-list-item__primary-text {
  color: #ffffff;
}
.ticketMenu .mat-mdc-menu-item .mdc-list-item__primary-text span{
  font-size: 14px;
  font-weight: 600;
}
.ticketMenu .mat-mdc-menu-item {
  min-height: 40px;
}
.attendeesMenu.mat-mdc-menu-panel {
  background-color: rgba(0, 0, 0, 0.9);
}
.attendeesMenu .mat-mdc-menu-item .mdc-list-item__primary-text {
  color: #ffffff;
}
.attendeesMenu .mat-mdc-menu-item .mdc-list-item__primary-text span{
  font-size: 14px;
  font-weight: 600;
}
.attendeesMenu .mat-mdc-menu-item {
  min-height: 30px;
}
.attendeesMenu .mat-mdc-menu-item.active {
  background-color: rgba(255, 100, 101, 0.75);
  border: 1px solid white;
}
.attendeesMenu .mat-mdc-menu-item:hover {
  background-color: rgba(255, 100, 101, 0.75);
}

#WEB3_CONNECT_MODAL_ID > div {
  z-index: 1001;
}
.walletconnect-modal__footer {
  flex-wrap: wrap;
}
#orca-element-unified-checkout {
  padding: 10px;
}
.waiting-tooltip.mdc-tooltip .mdc-tooltip__surface {
  max-width: 250px;
  text-align: center;
}
.text-tooltip {
  font-size: 16px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  white-space: pre-line;
}
.text-tooltip.mdc-tooltip .mdc-tooltip__surface {
  white-space: pre-line;
}

.grecaptcha-badge { visibility: hidden; width: 0 !important; }
.fee-management-template-modal {
  overflow-y: scroll;
}
.fee-management-template-modal .mdc-form-field>label {
  margin-bottom: 0;
}
.cms_fee_management_template .filter-input {
  flex-grow: 1;
}
.cms_fee_management_template .filter-input .mat-mdc-form-field-subscript-wrapper,
.cms_fee_management_template .search-by-producer-id-input .mat-mdc-form-field-subscript-wrapper{
  display: none;
}
.cms-events-template .filter-input .mat-mdc-form-field-subscript-wrapper,
.producers-list-modal-body .mat-mdc-form-field-subscript-wrapper,
.list-modal-body .mat-mdc-form-field-subscript-wrapper,
.cms_currency_management .filter-input .mat-mdc-form-field-subscript-wrapper,
.cms_user_suspended .mat-mdc-form-field-subscript-wrapper,
.cms_phone_black_list .filter-input .mat-mdc-form-field-subscript-wrapper,
.cms_phone_white_list .filter-input .mat-mdc-form-field-subscript-wrapper,
.cms-auto-approve-producers .filter-input .mat-mdc-form-field-subscript-wrapper {
    display: none;
}
.mat-bank-terms .mat-mdc-checkbox.mat-accent{
    --mdc-checkbox-unselected-focus-icon-color: #ffffff;
    --mdc-checkbox-unselected-hover-icon-color: #ffffff;
    --mdc-checkbox-disabled-unselected-icon-color: rgb(255 255 255);
    --mdc-checkbox-unselected-icon-color: rgba(255 255 255 / 60%);
    --mdc-checkbox-unselected-pressed-icon-color: rgb(255 255 255 / 60%);
}
.create-edit-currency-select .mat-mdc-text-field-wrapper {
  background-color: transparent;
  border: 1px solid #FF281B;
  border-radius: 40px;
  height: 45px;
}
.create-edit-currency-select .mdc-text-field .mdc-floating-label,
.create-edit-currency-select .mat-mdc-select-value{
  color: #fff !important;
  font-size: 12px;
}
.create-edit-currency-select .mat-mdc-select-arrow{
  width: 12px;
  height: 8px;
  background-image: url('/assets/images/svg-icons/down_angle_arrow.svg');
}
.create-edit-currency-select .mat-mdc-select-arrow svg {
  display: none;
}
.create-edit-currency-select .mdc-line-ripple {
  opacity: 0;
}
.currency-panel .mat-mdc-option.mdc-list-item{
  font-size: 12px;
  line-height: 1;
}
.create-edit-currency-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  padding-top: 16px;
}
.create-edit-currency-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 21px;
}
.currency-panel.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.currency-panel.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #FF6465;
}
.currency-panel.mat-primary .mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 5px;
}
.currency-panel .mdc-list-item__primary-text{
  color: #fff !important;
}
.currency-panel.mdc-menu-surface {
  background-color: #242831;
  color: #fff;
}
.mat-spotify .mdc-list-item__primary-text {
  width: 100%;
}
.mat-spotify.mdc-menu-surface {
  background-color: #202326;
  color: #fff;
}
.coupons-wrapper .ngx-pagination a, .coupons-wrapper .ngx-pagination button {
  color: #cacaca;
}
.coupons-wrapper .ngx-pagination a:hover, .coupons-wrapper .ngx-pagination button:hover {
  background: transparent;
}
/* Right Icon on Event page*/
.icon-right:after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
  vertical-align: middle;
  background-image: url('/../../assets/images/svg-icons/arrow_circle_right.svg');
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
}
/*New Mat Dialogs dark inputs*/
.mat-mdc-form-field.dark .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: #16112B;
    border-radius: 5px;
    border: 1px solid #252045;
}
.mat-mdc-form-field.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #B8A6CC;
}
.mat-mdc-form-field.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label *{
    font-family: 'Neue Haas Grotesk Display Pro', 'Open Sans Hebrew', sans-serif;
    font-size: 16px;
    font-weight: 500;
}
.mat-mdc-form-field.dark .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: transparent;
}
.mat-mdc-form-field.dark:not(.mat-form-field-no-animations) .mdc-text-field__input {
    color: #FFFFFF;
}
.mat-mdc-form-field.dark .mdc-text-field .mdc-text-field__input {
    caret-color: #86809b;
}

/* --------Mobiscroll Styles---------- */
/* --------Mobiscroll Datepicker: for Window---------- */
div.mbsc-fr-overlay {
  z-index: 1;
  background: rgba(0,0,0,0.6);
}
div.mbsc-windows .mbsc-fr-w {
  padding: 5%;
  background: #242831;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.65);
  border-radius: 25px;
  font: 14px/14px 'Montserrat', sans-serif;
  color: #FFFFFF;
  text-align: center;
  border: none;
}
div.mbsc-windows .mbsc-fr-hdr {
  padding-bottom: 5%;
  border-bottom: 1.5px solid #FF6465;
  font: 14px/14px 'Montserrat', sans-serif;
  color: #FF6465;
  background: transparent;
  text-transform: uppercase;
}
div.mbsc-windows .mbsc-sc-itm {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
}
div.mbsc-windows .mbsc-sc-whl-w {
  border-right: 1.5px solid #FF6465
}
div.mbsc-windows .mbsc-fr-btn-cont {
  border-top: none;
}
div.mbsc-windows .mbsc-fr-btn-w {
  background: transparent;
}
div.mbsc-windows.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
div.mbsc-windows .mbsc-fr-btn.mbsc-active {
  background: #FF6465;
  color: #FFFFFF;
}
div.mbsc-windows .mbsc-sc-whl-gr-c {
  border-bottom: 1.5px solid #FF6465;
}
div.mbsc-windows .mbsc-sc-whl-l{
  border-top: 1.5px solid #FF6465;
  border-bottom: 1.5px solid #FF6465;
}
div.mbsc-sc-btn .mbsc-sc-btn-plus .mbsc-ic .mbsc-ic-arrow-down5::before,
div.mbsc-windows.mbsc-no-touch .mbsc-sc-btn:hover,
div.mbsc-windows .mbsc-sc-btn {
  background: #FF6465;
}
div.mbsc-windows .mbsc-fr-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* --------Mobiscroll Datepicker: for IOS (iPad, iPhone, iPod)---------- */
div.mbsc-ios .mbsc-fr-w {
  background-color: #24262b;;
  color: #ffffff;
}
div.mbsc-ios.mbsc-sc.mbsc-fr-top .mbsc-fr-w,
div.mbsc-ios.mbsc-sc.mbsc-fr-bottom .mbsc-fr-w {
  background: #24262b;
}
div.mbsc-ios.mbsc-fr-top .mbsc-fr-btn-cont,
div.mbsc-ios.mbsc-fr-bottom .mbsc-fr-btn-cont {
  background: #24262b;
}
div.mbsc-ios .mbsc-sc-itm-sel, div.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm{
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
}
div.mbsc-ios.mbsc-fr-bottom .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
  /* background: #FF6465; */
  background: #24262b;
}
div.mbsc-ios div.mbsc-fr-hdr,
div.mbsc-ios .mbsc-fr-btn {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #FF6465;
  background: transparent;
  text-transform: uppercase;

}
div.mbsc-ios.mbsc-fr-top .mbsc-fr-btn-cont,div.mbsc-ios.mbsc-fr-bottom .mbsc-fr-btn-cont{
  background:#FF6465;
}
div.mbsc-ios .mbsc-sc-whl-l {
  border-top: 1.5px solid #FF6465;
  border-bottom: 1.5px solid #FF6465;
}
div.mbsc-ios .mbsc-sc-whl-o {
  background: linear-gradient(#242831, rgba(8,8,8,0) 52%, rgba(8,8,8,0) 48%, #242831);
}
div.mbsc-ios .mbsc-fr-overlay {
  background: rgba(0,0,0,0.6);
}
div.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
  background: #24262b;
}
/* --------Mobiscroll Datepicker: for Android---------- */
div.mbsc-material .mbsc-fr-w {
  min-width: 20em;
  width: 100%;
  padding: 5% 0;
  background: #242831;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.65);
  border-radius: 25px;
  font: 12px/12px 'Montserrat', sans-serif;
  color: #FFFFFF;
  border: none;
}
div.mbsc-material .mbsc-sc-whl-gr {
  padding: 5%;
}
div.mbsc-material .mbsc-sc-itm,
span.mbsc-dt-month {
  font-family: 'Montserrat', sans-serif;
  font-size: 9px;
  font-weight: normal;
}
div.mbsc-windows .mbsc-sc-whl-l,
div.mbsc-material .mbsc-sc-whl-l{
  border-top: 1.5px solid #FF6465;
  border-bottom: 1.5px solid #FF6465;
}
div.mbsc-windows .mbsc-fr-btn {
  font: 12px/12px 'Montserrat', sans-serif;
  color: #FF6465;
  background: transparent;
  text-transform: uppercase;
}
div.mbsc-material .mbsc-fr-hdr,
div.mbsc-material .mbsc-fr-btn {
  height: auto;
  font: 12px/12px 'Montserrat', sans-serif;
  color: #FF6465;
}
/* --------Mobiscroll Styles END---------- */
