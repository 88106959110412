:where(html[dir='ltr']) {
  * {
    direction: ltr;
  }
  .row > * {
    float: left;
  }
  h2 {
    text-align: left;
  }
  ::-webkit-input-placeholder {
    text-align: left;
  }
  :-moz-placeholder {
    text-align: left;
  }
  ::-moz-placeholder {
    text-align: left;
  }
  :-ms-input-placeholder {
    text-align: left;
  }
  .eventInfo th,
  .cms_web_hooks th,
  .cms_users th,
  .cms_events th,
  .cms_dashboard th {
    text-align: left;
  }
  .cms_payment_form .actions-box > div:first-child {
    border-right: 1px solid #d2d2d2;
  }
  .events .fa-position,
  .open_seller_coupons .fa-position,
  .salesmen .fa-position,
  .participants .fa-position,
  .cms_web_hooks .fa-position,
  .dashboard .fa-position {
    float: right;
  }
  .close-form,
  .close {
    float: left !important;
  }
  .open_seller_coupons table th {
    text-align: left;
  }
  .mutate-float-left {
    float: right;
  }
  .mutate-float-right {
    float: left;
  }
  .mutate-border-left {
    border-right: 1px solid #d7d7d7;
  }
  .mutate-text-right {
    text-align: left;
  }
  .settings-container mat-expansion-panel-header {
    padding: 0 24px 0 0;
  }
  .settings-container .btn-group > .btn {
    float: right;
  }
  /*margin-left 0-50*/
  .ml0 {
    margin-right: 0;
  }
  .ml5 {
    margin-right: 5px;
  }
  .ml10 {
    margin-right: 10px;
  }
  .ml15 {
    margin-right: 15px;
  }
  .ml20 {
    margin-right: 20px;
  }
  .ml25 {
    margin-right: 25px;
  }
  .ml30 {
    margin-right: 30px;
  }
  .ml35 {
    margin-right: 35px;
  }
  .ml40 {
    margin-right: 40px;
  }
  .ml50 {
    margin-right: 50px;
  }

  /*margin-top 0-50*/
  .mr0 {
    margin-left: 0;
  }
  .mr3 {
    margin-left: 3px;
  }
  .mr5 {
    margin-left: 5px;
  }
  .mr10 {
    margin-left: 10px;
  }
  .mr15 {
    margin-left: 15px;
  }
  .mr20 {
    margin-left: 20px;
  }
  .mr25 {
    margin-left: 25px;
  }
  .mr30 {
    margin-left: 30px;
  }
  .mr35 {
    margin-left: 35px;
  }
  .mr40 {
    margin-left: 40px;
  }
  .mr50 {
    margin-left: 50px;
  }

  /*padding-left 0-50*/
  .pl0 {
    padding-right: 0;
  }
  .pl5 {
    padding-right: 5px;
  }
  .pl10 {
    padding-right: 10px;
  }
  .pl15 {
    padding-right: 15px;
  }
  .pl20 {
    padding-right: 20px;
  }
  .pl25 {
    padding-right: 25px;
  }
  .pl30 {
    padding-right: 30px;
  }
  .pl35 {
    padding-right: 35px;
  }
  .pl40 {
    padding-right: 40px;
  }
  .pl50 {
    padding-right: 50px;
  }

  /*padding-right 0-50*/
  .pr0 {
    padding-left: 0;
  }
  .pr5 {
    padding-left: 5px;
  }
  .pr10 {
    padding-left: 10px;
  }
  .pr15 {
    padding-left: 15px;
  }
  .pr20 {
    padding-left: 20px;
  }
  .pr25 {
    padding-left: 25px;
  }
  .pr30 {
    padding-left: 30px;
  }
  .pr35 {
    padding-left: 35px;
  }
  .pr40 {
    padding-left: 40px;
  }
  .pr50 {
    padding-left: 50px;
  }

  .fb_icons .round {
    margin-left: -15px;
    display: inline;
  }

  .fb_icons .round:first-child {
    margin-left: 0px;
  }

  .terms * {
    direction: ltr;
  }

  .terms p,
  .terms span {
    text-align: left;
  }

  .bg_color h4 {
    text-align: left;
  }
  td.table-cell-phone-number {
    text-align: left;
  }
}
